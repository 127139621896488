import React, {useEffect, useState} from 'react'

const RangeSlider = (props) => {
    // const [value, setValue] = useState(Math.round(props.max_val/2))
    const [value, setValue] = useState(0)
    
    useEffect(() => {
        console.log(props)
        setValue(props.value === '' ? 0 : props.value)
    }, [props.value]);

    const handleSlider = (e) => {
        setValue(e.target.value);
        props.onslideChange(e);
    }
    
    let markarr = []
    if(isNaN(props.start_text) === false && isNaN(props.end_text) === false){
        for(let i=Number(props.start_text)+1;i<Number(props.end_text);i++){
            markarr.push(i)
            }
        }
    
    return (
        <>
            <div className='csRangeSlider'>
                <input type='range' name={props.name} min={Number(props.start_text)} max={props.max_val} value={value} onChange={(e) => handleSlider(e)} className='csRangeSlider__input' />
                <span className='csRangeSlider__placeholder'style={{width: `calc(${value*10}%`}} />
            </div>

            <div className='csRangeSliderIndicators'>
                <label>{props.start_text}</label>
                {
                    markarr.map(function(item, index){
                        return  <label key={index}>{item}</label>
                        }.bind(this))
                }
                
                <label>{props.end_text}</label>
            </div>
        </>
    )
}

export default RangeSlider
