import {getSubdomain} from "./service/common.services";
import config from './config.json';
let DATA
let ES_INDE
let API_PAT
let server=window['server']
if(server === "local"){
    ES_INDE="redeemx"
    API_PAT="https://devapi.voyce.it/"

}
else if(server === "dev"){
    ES_INDE=getSubdomain(window.location.hostname.replace("feedback",""))
    API_PAT="https://devapi.voyce.it/"


}
else {
    ES_INDE=getSubdomain(window.location.hostname.replace("feedback",""))
    API_PAT="https://api.voyce.it/"

}



export const ES_INDEX=ES_INDE
export const API_PATH=API_PAT
